import React, { Component } from 'react';
import { FiGrid } from "react-icons/fi";
import { BsPerson, BsReverseLayoutTextSidebarReverse, BsInstagram, BsTwitter, BsYoutube, BsLinkedin, BsFacebook } from "react-icons/bs";
import { RiShareBoxLine } from "react-icons/ri";
import { FaTelegramPlane } from "react-icons/fa";
import { MdComputer, MdEmail, MdLocationPin } from "react-icons/md";
import Slidervideo from "react-slick";
import Newsawards from "react-slick";

class Cardfour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      mobile: ''
    };
  }

  emailOnChange = (email) => {
    this.setState({ email });
  }

  mobileOnChange = (mobile) => {
    const re = /^[0-9\b]+$/;
    if (mobile === '' || re.test(mobile) && mobile.length < 11 && Number(mobile[0]) > 5) {
      this.setState({ mobile });
    }
  }

  render() {
    let { email, mobile } = this.state;

    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    var News_settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (

      <div className="App">
        <div className='id_app mobilever'>
          <div className='idwapper'>
            <div className='bottom_tab'>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#id" type="button" role="tab" aria-controls="home" aria-selected="true"><FiGrid /></button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#About" type="button" role="tab" aria-controls="profile" aria-selected="false"><BsPerson /></button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#blog" type="button" role="tab" aria-controls="contact" aria-selected="false"><BsReverseLayoutTextSidebarReverse /></button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#share" type="button" role="tab" aria-controls="contact" aria-selected="false"><RiShareBoxLine /></button>
                </li>
              </ul>

            </div>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="id" role="tabpanel" aria-labelledby="home-tab">
                <div className="tot_wapps">
                  <div className="bgwapper"></div>
                  <div className="cover_photo">
                    <img src="./images/cover_img.png" className="App-logo" alt="cover" />
                  </div>
                  <div className="social_icons">
                    <ul>
                      <li>
                        <a href="https://instagram.com/prateek_fire?utm_medium=copy_link" target="_blank"><BsInstagram /></a>
                      </li>
                      <li>
                        <a href="https://twitter.com/prateek_fire" target="_blank"><BsTwitter /></a>
                      </li>
                      {/* <li>
                                      <a href="#" target="_blank"><BsYoutube /></a>
                                    </li> */}
                      <li>
                        <a href="https://www.linkedin.com/in/prateekfire/" target="_blank"><BsLinkedin /></a>
                      </li>
                    </ul>
                  </div>
                  <div className="main-wapper">
                    <div className="person-wapid">
                      <div className="imgwap">
                        <img src="./images/id4.png" className="personimg" alt="Pratik Gauri" />
                      </div>
                      <div className="per_name">
                        <h2>Prateek Dwivedi</h2>
                        <p>Co-founder & CMO</p>
                      </div>
                    </div>
                    <div className="tele_lists">
                      <a href="https://t.me/thecryptobloke" className="link_btn"><FaTelegramPlane /> Chat on Telegram</a>
                    </div>
                    <div className="listof_icons">
                      <ul>
                        <li className="ic_id">
                          <a href="mailto:prateek@5ire.org" target="_blank"><MdEmail /> <span>Email</span> </a>
                        </li>
                        <li className="ic_id">
                          <a href="http://www.5ire.org/" target="_blank"><MdComputer /> <span>Website</span> </a>
                        </li>
                        <li className="ic_id">
                          <a href="https://goo.gl/maps/voT1D2QoLssG7bLM7" target="_blank"><MdLocationPin /> <span>Location</span> </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
              <div class="tab-pane fade" id="About" role="tabpanel" aria-labelledby="home-tab">
                <div className="tot_wapps">
                  <div className="bgwapper"></div>
                  <div className="abtsec">
                    <div className="abtme">
                      <h4 className="heads_side">About Me</h4>
                      <div className="abtwapp">
                        Prateek is a Blockchain enthusiast, investor and veteran Internet Marketer. He conceptualised the proprietary 5IRE consensus mechanism and reward protocols. In his internet marketing days, he clocked 8-figure affiliate revenues and helped incubate many startups. He founded Kaching Media and multiple internet marketing companies. He is also a heavy proponent of work life balance and healthy living.
                      </div>
                    </div>

                    <div className="abt_logos">
                      <h4 className="heads_side">About 5ire</h4>
                      <div className="img_logo">
                        <img src="./images/logo.png" alt="logo"></img>
                      </div>
                      <div className="abt_pages">
                        <p> 5ire is a fifth-generation blockchain bringing a paradigm shift from a for-profit to a for-benefit economy.</p>
                      </div>
                    </div>
                    <div className="videos_lg">
                      <h4 className="heads_side">Videos</h4>
                      <div className="video_slider">
                        <Slidervideo {...settings}>
                          <div>
                            <div className="video_sli">
                              <iframe width="100%" height="250" src="https://www.youtube.com/embed/JLr7o0UDY9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                          </div>
                          <div>
                            <div className="video_sli">
                              <iframe width="100%" height="250" src="https://www.youtube.com/embed/JLr7o0UDY9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                          </div>
                          <div>
                            <div className="video_sli">
                              <iframe width="100%" height="250" src="https://www.youtube.com/embed/JLr7o0UDY9k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                          </div>


                        </Slidervideo>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="blog" role="tabpanel" aria-labelledby="home-tab">
                <div className="tot_wapps">
                  <div className="bgwapper"></div>
                  <div className="abtsec">
                    <div className="join_newsletter">
                      <h5>Join the newsletter</h5>
                      <p>No Spam, weekly update about 5irechain</p>
                      <form>
                        <input className="form-control" type="text"></input>
                        <button className="btn buttoncls" type="button">Join</button>
                      </form>
                    </div>
                    <div className="News_awards">
                      <h4 className="heads_side">News & Awards</h4>
                      <Newsawards {...News_settings}>
                        <div>
                          <div className="aww-img">
                            <img className="img_lists" src="./images/aea.jpg"></img>
                          </div>
                        </div>

                        <div>
                          <div className="aww-img">
                            <img className="img_lists" src="./images/aea-2.jpg"></img>
                          </div>
                        </div>




                      </Newsawards>
                    </div>
                    <div className="blog_lists">
                      <h4 className="heads_side">Blogs</h4>
                      <div className="fulllist">
                        <ul>
                          <li>
                            <img src="./images/1.png" />
                            <h6 className="ft-osbold">We Continue to Grow Despite the Bear Market</h6>
                            <div className="enq">
                              <a href="https://blog.5ire.org/we-continue-to-grow-despite-the-bear-market-32a6c1d51693" target="_blank" className="ft-med">View Blog</a>
                            </div>
                          </li>
                          <li>
                            <img src="./images/2.png" />
                            <h6 className="ft-osbold">5ire Can Help With Disaster Relief</h6>
                            <div className="enq">
                              <a href="https://blog.5ire.org/5ire-can-help-with-disaster-relief-199152204bc4" target="_blank" className="ft-med">View Blog</a>
                            </div>
                          </li>
                          <li>
                            <img src="./images/3.png" />
                            <h6 className="ft-osbold">5ire and Food Security</h6>
                            <div className="enq">
                              <a href="https://blog.5ire.org/5ire-and-food-security-aeada551b49a" target="_blank" className="ft-med">View Blog</a>
                            </div>
                          </li>
                          <li>
                            <img src="./images/4.png" />
                            <h6 className="ft-osbold">International Women’s Day 2022: The Women of 5ire</h6>
                            <div className="enq">
                              <a href="https://blog.5ire.org/international-womens-day-2022-the-women-of-5ire-95fde4be376f" target="_blank" className="ft-med">View Blog</a>
                            </div>
                          </li>
                          <li>
                            <img src="./images/5.png" />
                            <h6 className="ft-osbold">5ireChain’s Transaction Processing</h6>
                            <div className="enq">
                              <a href="https://blog.5ire.org/5irechains-transaction-processing-e10cdb6ae521" target="_blank" className="ft-med">View Blog</a>
                            </div>
                          </li>
                          <li>
                            <img src="./images/6.jpeg" />
                            <h6 className="ft-osbold"> Growth Strategist, Advisor</h6>
                            <div className="enq">
                              <a href="https://blog.5ire.org/growth-strategist-advisor-and-global-connector-dr-richard-swart-joins-5ire-as-strategic-advisor-8762507ecfbb" target="_blank" className="ft-med">View Blog</a>
                            </div>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="share" role="tabpanel" aria-labelledby="home-tab">
                <div className="tot_wapps">
                  <div className="bgwapper"></div>
                  <div className="abtsec">
                    <div className="sharefoll">
                      <div className="white_label">
                        <img src="./images/logo.png" alt="logo"></img>
                      </div>
                      <div className="followus">
                        <p>Follow us</p>
                        <div className="social_icons">
                          <ul>
                            <li>
                              <a href="https://www.facebook.com/5irechain/" target="_blank"><BsFacebook /></a>
                            </li>
                            <li>
                              <a href="https://twitter.com/5ireChain" target="_blank"><BsTwitter /></a>
                            </li>
                            <li>
                              <a href="https://www.instagram.com/5irechain" target="_blank"><BsInstagram /></a>
                            </li>
                            <li>
                              <a href="https://www.linkedin.com/company/5irechain/" target="_blank"><BsLinkedin /></a>
                            </li>
                            <li>
                              <a href="https://youtube.com/c/5ireChainOfficial" target="_blank"><BsYoutube /></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="share_lists">
                      <h4 className="heads_side">SHARE</h4>

                      <div className="rowshares">
                        <ul class="nav nav-tabs" id="myTab1" role="tablist">
                          <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="home-tab1" data-bs-toggle="tab" data-bs-target="#Email" type="button" role="tab" aria-controls="home" aria-selected="true">Email</button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="profile-tab2" data-bs-toggle="tab" data-bs-target="#text" type="button" role="tab" aria-controls="profile" aria-selected="false">Text</button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="contact-tab3" data-bs-toggle="tab" data-bs-target="#QRcode" type="button" role="tab" aria-controls="contact" aria-selected="false">QR Code</button>
                          </li>
                        </ul>


                        <div class="tab-content" id="myTabContent1">
                          <div class="tab-pane fade show active" id="Email" role="tabpanel" aria-labelledby="home-tab1">
                            <div className="email_sends">
                              <input
                                className="form-control"
                                type='email'
                                placeholder="Enter the Email"
                                onChange={(e) => this.emailOnChange(e.target.value)}
                              />
                              <a href={`mailto:${email}?subject=Just Tap Card&body=Hi. This is Test`} className="btn btn-primary btncolors">Send</a>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="text" role="tabpanel" aria-labelledby="home-tab1">
                            <div className="email_sends">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Enter the Mobile Number"
                                value={mobile}
                                onChange={(e) => this.mobileOnChange(e.target.value)}
                              />
                              <a href={`sms:${mobile};?&body=Hi. This is Test.`} className="btn btn-primary btncolors">Send</a>
                            </div>
                          </div>
                          <div class="tab-pane fade" id="QRcode" role="tabpanel" aria-labelledby="home-tab1">
                            <div class="qrscannersli">
                              <div class="lftscanners">
                                <img src="./images/scan.png" alt="justttab" />
                              </div>
                              <div class="rtscanners">
                                <h5 class="ft-med">Scan QR Code</h5>
                                <img src="./images/qr-code-4.png" alt="justttab" />
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="power_lis">
                      <p>Powered by   <a href='https://justtap.one/home'><span>Justtap.one</span></a></p> 
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

export default Cardfour;
